<template>
  <div class="body_page">
    <!-- <h1>Laboratoriya tekshiruvi</h1> -->
    <div class="tabs">
      <input
        type="radio"
        id="tab1"
        name="tab-control"
        @click="changeTabs(1)"
        checked
      />
      <input type="radio" id="tab2" name="tab-control" @click="changeTabs(2)" />
      <input type="radio" id="tab3" name="tab-control" @click="changeTabs(3)" />
      <input type="radio" id="tab4" name="tab-control" @click="changeTabs(4)" />
      <input type="radio" id="tab5" name="tab-control" @click="changeTabs(5)" />
      <ul>
        <li title="Features">
          <label
            class="flex_class"
            for="tab1"
            role="button"
            :style="show_tab == 1 ? 'color:#428bff' : ''"
            ><svg viewBox="0 0 24 24" :class="show_tab == 1 ? 'svg_icon' : ''">
              <path
                d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z"
              /></svg
            ><br /><span>{{
              lang == "uz" ? "Identifikatsiya" : "Идентификация"
            }}</span></label
          >
        </li>
        <li title="Delivery Contents">
          <label
            class="flex_class"
            for="tab2"
            role="button"
            :style="show_tab == 2 ? 'color:#428bff' : ''"
            ><svg
              class="svg-icon"
              viewBox="0 0 21 21"
              :class="show_tab == 2 ? 'svg_icon' : ''"
            >
              <path
                d="M8.749,9.934c0,0.247-0.202,0.449-0.449,0.449H4.257c-0.247,0-0.449-0.202-0.449-0.449S4.01,9.484,4.257,9.484H8.3C8.547,9.484,8.749,9.687,8.749,9.934 M7.402,12.627H4.257c-0.247,0-0.449,0.202-0.449,0.449s0.202,0.449,0.449,0.449h3.145c0.247,0,0.449-0.202,0.449-0.449S7.648,12.627,7.402,12.627 M8.3,6.339H4.257c-0.247,0-0.449,0.202-0.449,0.449c0,0.247,0.202,0.449,0.449,0.449H8.3c0.247,0,0.449-0.202,0.449-0.449C8.749,6.541,8.547,6.339,8.3,6.339 M18.631,4.543v10.78c0,0.248-0.202,0.45-0.449,0.45H2.011c-0.247,0-0.449-0.202-0.449-0.45V4.543c0-0.247,0.202-0.449,0.449-0.449h16.17C18.429,4.094,18.631,4.296,18.631,4.543 M17.732,4.993H2.46v9.882h15.272V4.993z M16.371,13.078c0,0.247-0.202,0.449-0.449,0.449H9.646c-0.247,0-0.449-0.202-0.449-0.449c0-1.479,0.883-2.747,2.162-3.299c-0.434-0.418-0.714-1.008-0.714-1.642c0-1.197,0.997-2.246,2.133-2.246s2.134,1.049,2.134,2.246c0,0.634-0.28,1.224-0.714,1.642C15.475,10.331,16.371,11.6,16.371,13.078M11.542,8.137c0,0.622,0.539,1.348,1.235,1.348s1.235-0.726,1.235-1.348c0-0.622-0.539-1.348-1.235-1.348S11.542,7.515,11.542,8.137 M15.435,12.629c-0.214-1.273-1.323-2.246-2.657-2.246s-2.431,0.973-2.644,2.246H15.435z"
              ></path></svg
            ><br /><span>{{
              lang == "uz" ? "Kasallik tarixi" : "Данные анамнеза"
            }}</span></label
          >
        </li>
        <li title="Shipping">
          <label
            class="flex_class"
            for="tab3"
            role="button"
            :style="show_tab == 3 ? 'color:#428bff' : ''"
            ><svg
              class="svg-icon"
              viewBox="0 0 20 20"
              :class="show_tab == 3 ? 'svg_icon' : ''"
            >
              <path
                d="M15.684,16.959L10.879,8.52c0.886-0.343,1.517-1.193,1.517-2.186c0-1.296-1.076-2.323-2.396-2.323S7.604,5.037,7.604,6.333c0,0.993,0.63,1.843,1.517,2.186l-4.818,8.439c-0.189,0.311,0.038,0.708,0.412,0.708h10.558C15.645,17.667,15.871,17.27,15.684,16.959 M8.562,6.333c0-0.778,0.645-1.382,1.438-1.382s1.438,0.604,1.438,1.382c0,0.779-0.645,1.412-1.438,1.412S8.562,7.113,8.562,6.333 M5.55,16.726L10,8.91l4.435,7.815H5.55z M15.285,9.62c1.26-2.046,1.26-4.525,0-6.572c-0.138-0.223-0.064-0.512,0.162-0.646c0.227-0.134,0.521-0.063,0.658,0.16c1.443,2.346,1.443,5.2,0,7.546c-0.236,0.382-0.641,0.17-0.658,0.159C15.221,10.131,15.147,9.842,15.285,9.62 M13.395,8.008c0.475-1.063,0.475-2.286,0-3.349c-0.106-0.238,0.004-0.515,0.246-0.62c0.242-0.104,0.525,0.004,0.632,0.242c0.583,1.305,0.583,2.801,0,4.106c-0.214,0.479-0.747,0.192-0.632,0.242C13.398,8.523,13.288,8.247,13.395,8.008 M3.895,10.107c-1.444-2.346-1.444-5.2,0-7.546c0.137-0.223,0.431-0.294,0.658-0.16c0.226,0.135,0.299,0.424,0.162,0.646c-1.26,2.047-1.26,4.525,0,6.572c0.137,0.223,0.064,0.512-0.162,0.646C4.535,10.277,4.131,10.489,3.895,10.107 M5.728,8.387c-0.583-1.305-0.583-2.801,0-4.106c0.106-0.238,0.39-0.346,0.631-0.242c0.242,0.105,0.353,0.382,0.247,0.62c-0.475,1.063-0.475,2.286,0,3.349c0.106,0.238-0.004,0.515-0.247,0.62c-0.062,0.027-0.128,0.04-0.192,0.04C5.982,8.668,5.807,8.563,5.728,8.387"
              ></path></svg
            ><br /><span>{{
              lang == "uz" ? "Xavf omillari" : "Факторы риска"
            }}</span></label
          >
        </li>
        <li title="Returns">
          <label
            class="flex_class"
            for="tab4"
            role="button"
            :style="show_tab == 4 ? 'color:#428bff' : ''"
            ><svg
              class="svg-icon"
              viewBox="0 0 20 20"
              :class="show_tab == 4 ? 'svg_icon' : ''"
            >
              <path
                d="M18.737,9.691h-5.462c-0.279,0-0.527,0.174-0.619,0.437l-1.444,4.104L8.984,3.195c-0.059-0.29-0.307-0.506-0.603-0.523C8.09,2.657,7.814,2.838,7.721,3.12L5.568,9.668H1.244c-0.36,0-0.655,0.291-0.655,0.655c0,0.36,0.294,0.655,0.655,0.655h4.8c0.281,0,0.532-0.182,0.621-0.45l1.526-4.645l2.207,10.938c0.059,0.289,0.304,0.502,0.595,0.524c0.016,0,0.031,0,0.046,0c0.276,0,0.524-0.174,0.619-0.437L13.738,11h4.999c0.363,0,0.655-0.294,0.655-0.655C19.392,9.982,19.1,9.691,18.737,9.691z"
              ></path></svg
            ><br /><span>{{
              lang == "uz" ? "Doimiy dori-darmonlar" : "Регулярные лекарства"
            }}</span></label
          >
        </li>
        <li title="Initials">
          <label
            class="flex_class"
            for="tab5"
            role="button"
            :style="show_tab == 5 ? 'color:#428bff' : ''"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              class="svg-icon"
              :class="show_tab == 5 ? 'svg_icon' : ''"
            >
              <path
                d="M142.4 21.9c5.6 16.8-3.5 34.9-20.2 40.5L96 71.1V192c0 53 43 96 96 96s96-43 96-96V71.1l-26.1-8.7c-16.8-5.6-25.8-23.7-20.2-40.5s23.7-25.8 40.5-20.2l26.1 8.7C334.4 19.1 352 43.5 352 71.1V192c0 77.2-54.6 141.6-127.3 156.7C231 404.6 278.4 448 336 448c61.9 0 112-50.1 112-112V265.3c-28.3-12.3-48-40.5-48-73.3c0-44.2 35.8-80 80-80s80 35.8 80 80c0 32.8-19.7 61-48 73.3V336c0 97.2-78.8 176-176 176c-92.9 0-168.9-71.9-175.5-163.1C87.2 334.2 32 269.6 32 192V71.1c0-27.5 17.6-52 43.8-60.7l26.1-8.7c16.8-5.6 34.9 3.5 40.5 20.2zM480 224a32 32 0 1 0 0-64 32 32 0 1 0 0 64z"
              />
            </svg>
            <br /><span>{{
              lang == "uz" ? "Dastlabki ma'lumotlar" : "Данные при поступл."
            }}</span></label
          >
        </li>
      </ul>

      <div
        class="slider"
        :style="
          show_tab == 1
            ? 'width:20%'
            : show_tab == 2
            ? 'width:57%'
            : show_tab == 3
            ? 'width:94%'
            : show_tab == 4
            ? 'width:135%'
            : 'width:179%'
        "
      >
        <div class="indicator"></div>
      </div>
      <div class="content">
        <section v-if="show_tab == 1">
          <div class="flex_block_mobile">
            <div class="flex_block_item_mobile" style="width: 48%">
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Vazni :" : "Вес :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="weight"
                  placeholder="67"
                  v-model="weight"
                  required
                />
              </div>
            </div>
            <div class="flex_block_item_mobile" style="width: 48%">
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Bo'yi :" : "Рост :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="growth"
                  placeholder="175"
                  v-model="growth"
                  @input="setQuetletIndex()"
                  required
                />
              </div>
            </div>
          </div>
          <div class="flex_block_mobile">
            <div class="flex_block_item_mobile" style="width: 48%">
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Ketle indeksi :" : "Индекс Кетле :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="quetelet_index"
                  placeholder="1"
                  v-model="quetelet_index"
                  required
                />
              </div>
            </div>
            <div class="flex_block_item_mobile" style="width: 48%">
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Hozirda klinik sinovda ishtirok etmoqdami ?"
                  : "Участвует ли в настоящее время в клиническом исследовании ?"
              }}</span>
              <div class="input_field select_option">
                <select v-model="clinical_trial">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
          </div>
        </section>
        <section v-if="show_tab == 2">
          <div class="flex_block_mobile">
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "O'tmishdagi miokard infarkti :"
                  : "Инфаркт миокарда в прошлом :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="myocardial_infarction">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "Stenokardiya :" : "Стенокардия :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="angina_pectoris">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Yurak yetishmovchiligi :"
                  : "Застойная сердечная недостаточность :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="heart_failure">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "Insult :" : "Инсульт :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="stroke">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Periferik arteriya kasalliklari :"
                  : "Заболевание периферических артерий :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="peripheral_arterial">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Surunkali buyrak etishmovchiligi :"
                  : "Хроническая почечная недостаточность :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="renal_failure">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Surunkali o'pka kasalligi :"
                  : "Хроническое заболевание легких :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="lung_disease">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Perkutan koronar aralashuvlar :"
                  : "Чрезкожные коронарные вмешательства :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="percutaneous_coronary">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Koronar shuntirlash :"
                  : "Коронарное шунтирование :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="coronary_bypass">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Koronar stenozlar > 50% :"
                  : "Коронарные стенозы > 50% :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="coronary_stenoses">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <!-- <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Bemorning o'tkir koronar pitologiya haqidagi bilimlari :"
                  : "Наличие знаний пациента об острой каронарной питологии :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="acute_coronary">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{
                      lang == "uz"
                        ? "sezilarli darajada emas"
                        : "не значительно"
                    }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div> -->
            </div>
          </div>
        </section>
        <section v-if="show_tab == 3">
          <div class="flex_block_mobile">
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz" ? "Chekish :" : "Курение :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="smoking">
                  <option value="1">
                    {{ lang == "uz" ? "chekmagan" : "не курил" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "chekadi" : "курит" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "tashlagan" : "бросил" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "Qandli diabet :" : "Сахарный диабет :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="diabetes_mellitus">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">
                    {{ lang == "uz" ? "ha(parhez)" : "да (диета)" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "ha(tabletkalar)" : "да (таблетки)" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "ha(insulin)" : "да (инсулин)" }}
                  </option>
                  <option value="5">
                    {{
                      lang == "uz"
                        ? "yangi tashxis qo'yilgan"
                        : "вновь диагностированный"
                    }}
                  </option>
                  <option value="6">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Qandli diabet avlodida mavjudligi :"
                  : "Семейный анамнез по сахарному диабету:"
              }}</span>
              <div class="input_field select_option">
                <select v-model="family_diabetes">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Arterial gipertenziya :"
                  : "Артериальная гипертензия :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="arterial_hypertension">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Giperxolesterinemiya :"
                  : "Гипер-холестеринемия :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="hypercholesterolemia">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Giperxolesterinemiyaning avlodida mavjudligi :"
                  : "Семейный анмнез гиперхолестеринемии :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="family_hypercholester">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Yurak ishemik kasalligining avlodida mavjudligi :"
                  : "Семейный анамнез ИБС :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="family_coronary_artery">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Gipertoniyaning avlodida mavjudligi :"
                  : "Семейный анализ ГБ  :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="family_analysis_hd">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <!-- <span style="font-size: 17px">{{
                lang == "uz"
                  ? "V.S.ning oilaviy tarixi :"
                  : "Семейный анамнез по ВС :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="family_vs">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div> -->
              <!-- <span style="font-size: 17px"
                >{{ lang == "uz" ? Oilada gipertoniya kasalligiga chalinganlar mavjudmi :</span
              >
              <div class="input_field select_option">
                <select v-model="family_hypertension">
                  <option value="1">{{ lang == "uz" ? yo'q</option>
                  <option value="2">{{ lang == "uz" ? ha</option>
                  <option value="3">{{ lang == "uz" ? noma'lum</option>
                </select>
                <div class="select_arrow"></div>
              </div> -->
            </div>
          </div>
        </section>
        <section v-if="show_tab == 4">
          <div class="flex_block_mobile">
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz" ? "Aspirin :" : "Аспирин :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="aspirin">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Boshqa antitrombotik dorilar :"
                  : "Другие антитромботи-ческие препараты :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="antithrombotic_drugs">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">
                    {{ lang == "uz" ? "tiklopidin" : "тиклопидин" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "klopidogrel" : "клопидогрель" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "boshqa" : "другой" }}
                  </option>
                  <option value="5">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "Antikoagulyantlar :" : "Анти-коагулянты :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="anticoagulants">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">
                    {{
                      lang == "uz"
                        ? "Vit K antagonistlari"
                        : "антагонисты  Vit К"
                    }}
                  </option>
                  <option value="3">
                    {{
                      lang == "uz"
                        ? "og'iz trombin ingibitorlari"
                        : "пероральные ингибиторы тромбина"
                    }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "boshqa" : "другой" }}
                  </option>
                  <option value="5">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "Beta-blokerlar :" : "Бета-блокаторы :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="beta_blockers">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Angiotensinga aylantiruvchi ferment ingibatorlari :"
                  : "Ингибиторы АПФ :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="ace_inhibitors">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Angiotensin II retseptorlari antagonistlari :"
                  : "Антагонисты рецепторов к ангиотензину II :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="angiotensin_2">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "Statinlar :" : "Статины :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="statins">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
            <div class="flex_block_item_mobile">
              <span style="font-size: 17px">{{
                lang == "uz" ? "Nitratlar :" : "Нитраты :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="nitrates">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "Ca kanal blokerlari :"
                  : "Блокаторы Са каналов :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="channel_blockers">
                  <option value="1">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                  <option value="2">{{ lang == "uz" ? "ha" : "да" }}</option>
                  <option value="3">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <!-- <span style="font-size: 17px"
                >{{ lang == "uz" ? Lipidlarni kamaytiradigan statin bo'lmagan dorilar :</span
              >
              <div class="input_field select_option">
                <select v-model="lipid_lowering">
                  <option value="1">{{ lang == "uz" ? yo'q</option>
                  <option value="2">{{ lang == "uz" ? ezetimibe</option>
                  <option value="3">{{ lang == "uz" ? fibratlar</option>
                  <option value="4">{{ lang == "uz" ? boshqa</option>
                  <option value="5">{{ lang == "uz" ? noma'lum</option>
                </select>
                <div class="select_arrow"></div>
              </div> -->
            </div>
          </div>
        </section>
        <section v-if="show_tab == 5">
          <div class="flex_block_mobile">
            <div class="flex_block_item_mobile" style="width: 24%">
              <span style="font-size: 17px">{{
                lang == "uz" ? "Asosiy simptom :" : "Основной симптом :"
              }}</span>
              <details>
                <summary style="font-size: 16px !important">
                  {{
                    lang == "uz"
                      ? syndroms != ""
                        ? "Tanlandi !"
                        : "Simptomni tanlang"
                      : syndroms != ""
                      ? "Выбран !"
                      : "Выберите симптома"
                  }}
                </summary>
                <ul class="multi_ul">
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="red"
                        @input="syndromArr(1)"
                      />{{
                        lang == "uz" ? "simptomsiz" : "бессимптомный"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="orange"
                        @input="syndromArr(2)"
                      />{{
                        lang == "uz" ? "ko'krakdagi og'riq" : "боль в груди"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="yellow"
                        @input="syndromArr(3)"
                      />{{ lang == "uz" ? "nafas qisilishi" : "одышка" }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="green"
                        @input="syndromArr(4)"
                      />{{ lang == "uz" ? "zaiflik" : "слабость" }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="blue"
                        @input="syndromArr(5)"
                      />{{
                        lang == "uz" ? "hushidan ketish" : "синкопа"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="purple"
                        @input="syndromArr(6)"
                      />{{
                        lang == "uz"
                          ? "qon aylanishini to'xtatish / to'satdan o'limni to'xtatdi"
                          : "остановка кровообращиения/прекращенная внезапная смерть"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="magenta"
                        @input="syndromArr(7)"
                      />{{ lang == "uz" ? "boshqa" : "другое" }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="lightpink"
                        @input="syndromArr(8)"
                      />{{ lang == "uz" ? "noma'lum" : "не известно" }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="fc"
                        value="white"
                        @input="syndromArr(9)"
                      />{{ lang == "uz" ? "sovuq ter" : "холодный пот" }}</label
                    >
                  </li>
                </ul>
              </details>
              <!-- <span style="font-size: 17px">{{
                lang == "uz" ? "Asosiy simptom :" : "Основной симптом :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="main_symptom">
                  <option value="1">
                    {{ lang == "uz" ? "simptomsiz" : "бессимптомный" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "ko'krakdagi og'riq" : "боль в груди" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "nafas qisilishi" : "одышка" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "zaiflik" : "слабость" }}
                  </option>
                  <option value="5">
                    {{ lang == "uz" ? "hushidan ketish" : "синкопа" }}
                  </option>
                  <option value="6">
                    {{
                      lang == "uz"
                        ? "qon aylanishini to'xtatish / to'satdan o'limni to'xtatdi"
                        : "остановка кровообращиения/прекращенная внезапная смерть"
                    }}
                  </option>
                  <option value="7">
                    {{ lang == "uz" ? "boshqa" : "другое" }}
                  </option>
                  <option value="8">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                  <option value="9">
                    {{ lang == "uz" ? "sovuq ter" : "холодный пот" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div> -->

              <!-- <span style="font-size: 17px">{{
                lang == "uz" ? "Asosiy sindrom :" : "Основной синдром :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="major_syndrome">
                  <option value="1">
                    {{ lang == "uz" ? "simptomsiz" : "бессимптомный" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "ko'krakdagi og'riq" : "боль в груди" }}
                  </option>
                  <option value="3">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + nafas qisilishi"
                        : "боль в груди + одышка"
                    }}
                  </option>
                  <option value="4">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + zaiflik"
                        : "боль в груди + слабость"
                    }}
                  </option>
                  <option value="5">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + sovuq ter"
                        : "боль в груди + холодный пот"
                    }}
                  </option>
                  <option value="6">
                    {{ lang == "uz" ? "nafas qisilishi" : "одышка" }}
                  </option>
                  <option value="7">
                    {{
                      lang == "uz"
                        ? "nafas qisilishi + zaiflik"
                        : "одышка + слабость"
                    }}
                  </option>
                  <option value="8">
                    {{ lang == "uz" ? "hushidan ketish" : "синкопа" }}
                  </option>
                  <option value="9">
                    {{ lang == "uz" ? "zaiflik" : "слабость" }}
                  </option>
                  <option value="10">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + nafas qisilishi + zaiflik"
                        : "боль в груди + одышка + слабость"
                    }}
                  </option>
                  <option value="11">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + nafas qisilishi + zaiflik + sovuq ter"
                        : "боль в груди + одышка + слабость + холод пот"
                    }}
                  </option>
                  <option value="12">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + nafas qisilishi + sovuq ter"
                        : "боль в груди + одышка + холод пот"
                    }}
                  </option>
                  <option value="13">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + zaiflik + senkop"
                        : "боль в груди + слабост + синкоп"
                    }}
                  </option>
                  <option value="14">
                    {{
                      lang == "uz"
                        ? "ko'krak og'rig'i + zaiflik + sovuq ter"
                        : "боль в груди + слабост + холод пот"
                    }}
                  </option>
                  <option value="15">
                    {{
                      lang == "uz"
                        ? "nafas qisilishi + zaiflik"
                        : "одышки + слобасть"
                    }}
                  </option>
                  <option value="16">
                    {{
                      lang == "uz"
                        ? "nafas qisilishi + zaiflik + sovuq ter"
                        : "одышки + слобасть + холод пот"
                    }}
                  </option>
                  <option value="17">
                    {{
                      lang == "uz"
                        ? "zaiflik + sovuq ter"
                        : "слабость + холод пот"
                    }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div> -->
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Yurak urishi :" : "ЧСС"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="heart_rate"
                  placeholder="80"
                  v-model="heart_rate"
                  required
                />
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Sistolik qon bosimi :" : "Сист.АД"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="syst_bp"
                  placeholder="150"
                  v-model="syst_bp"
                  required
                />
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Diastolik qon bosimi :" : "Диаст.АД"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="diast_bp"
                  placeholder="90"
                  v-model="diast_bp"
                  required
                />
              </div>
            </div>
            <div class="flex_block_item_mobile" style="width: 24%">
              <span style="font-size: 17px">{{
                lang == "uz" ? "EKG ritmi :" : "Ритм ЭКГ :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="ecg_rhythm">
                  <option value="1">
                    {{ lang == "uz" ? "sinus" : "синусовый" }}
                  </option>
                  <option value="2">
                    {{
                      lang == "uz"
                        ? "atriyal fibrilatsiya / chayqalish"
                        : "мерцание/трепетание предсердий"
                    }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "EKS ritmi" : "ритм ЭКС" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "boshqa" : "другой" }}
                  </option>
                  <option value="5">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "QRS kompleksining shakli :"
                  : "Форма комплекса QRS :"
              }}</span>
              <details>
                <summary style="font-size: 16px !important">
                  {{
                    lang == "uz"
                      ? qrs_datas != ""
                        ? "Tanlandi !"
                        : "QRS shaklini tanlang"
                      : qrs_datas != ""
                      ? "Выбран !"
                      : "Выберите QRS"
                  }}
                </summary>
                <ul class="multi_ul">
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="gc"
                        value="red"
                        @input="qrsComplexArr(1)"
                      />{{ lang == "uz" ? "normal" : "нормал" }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="gc"
                        value="orange"
                        @input="qrsComplexArr(2)"
                      />{{
                        lang == "uz"
                          ? "Gis to'plamining chap oyoqcha blokadasi"
                          : "БЛНПГ (блокада левой ножки пучка Гиса)"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="gc"
                        value="yellow"
                        @input="qrsComplexArr(3)"
                      />{{
                        lang == "uz"
                          ? "Gis to'plamining o'ng oyoqcha blokadasi"
                          : "БЛНПГ (блокада правой ножки пучка Гиса)"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="gc"
                        value="green"
                        @input="qrsComplexArr(4)"
                      />{{
                        lang == "uz" ? "to'liq blokada" : "Полная блокада"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="gc"
                        value="blue"
                        @input="qrsComplexArr(5)"
                      />{{
                        lang == "uz" ? "to'liqsiz blokada" : "Неполная блокада"
                      }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="gc"
                        value="magenta"
                        @input="qrsComplexArr(6)"
                      />{{ lang == "uz" ? "boshqa holat" : "другое" }}</label
                    >
                  </li>
                  <li class="multi_li">
                    <label
                      ><input
                        type="checkbox"
                        name="gc"
                        value="lightpink"
                        @input="qrsComplexArr(7)"
                      />{{ lang == "uz" ? "noma'lum" : "не известно" }}</label
                    >
                  </li>
                </ul>
              </details>
              <span style="font-size: 17px">{{
                lang == "uz" ? "ST-T o'zgarishlari :" : "Изменения ST-T :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="st_t_changes">
                  <option value="1">
                    {{ lang == "uz" ? "norma" : "норма" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "ST balandligi" : "элевация ST" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "ST depressiyasi" : "депрессия ST" }}
                  </option>
                  <option value="4">
                    {{
                      lang == "uz"
                        ? "T inversiyasi (ST balandligi yo'q)"
                        : "инверсия Т (без подъема ST)"
                    }}
                  </option>
                  <option value="5">
                    {{ lang == "uz" ? "boshqa" : "другой" }}
                  </option>
                  <option value="6">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                  <option value="7">
                    {{
                      lang == "uz"
                        ? "Q to'lqinining o'zgarishi"
                        : "изменение зубца Q"
                    }}
                  </option>
                  <option value="8">
                    {{
                      lang == "uz"
                        ? "Q to'lqini o'zgarishlarining lokalizatsiyasi"
                        : "локализация изменений зубца Q"
                    }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz"
                  ? "EKG o'zgarishlari lokalizatsiyasi :"
                  : "Локализация изменений на ЭКГ :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="ecg_changes">
                  <option value="1">
                    {{ lang == "uz" ? "old" : "передняя" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "orqa" : "задние" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "boshqa" : "другая" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                  <option value="5">{{ lang == "uz" ? "yo'q" : "нет" }}</option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "Troponin T darajasi :" : "Уровень тропонина Т :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="troponin_t_level">
                  <option value="1">
                    {{ lang == "uz" ? "o'lchanmagan" : "не измерялся" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "ko'tarilgan" : "повышен" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "baland emas" : "не повышен" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
            </div>
            <div class="flex_block_item_mobile" style="width: 24%">
              <span style="font-size: 17px">{{
                lang == "uz" ? "Troponin I darajasi :" : "Уровень тропонина I :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="troponin_i_level">
                  <option value="1">
                    {{ lang == "uz" ? "o'lchanmagan" : "не измерялся" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "ko'tarilgan" : "повышен" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "baland emas" : "не повышен" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <span style="font-size: 17px">{{
                lang == "uz" ? "MV-KFK :" : "Уровень массы МВ-КФК :"
              }}</span>
              <div class="input_field select_option">
                <select v-model="mv_cpk">
                  <option value="1">
                    {{ lang == "uz" ? "o'lchanmagan" : "не измерялся" }}
                  </option>
                  <option value="2">
                    {{ lang == "uz" ? "ko'tarilgan" : "повышен" }}
                  </option>
                  <option value="3">
                    {{ lang == "uz" ? "baland emas" : "не повышен" }}
                  </option>
                  <option value="4">
                    {{ lang == "uz" ? "noma'lum" : "не известно" }}
                  </option>
                </select>
                <div class="select_arrow"></div>
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz"
                    ? "Qabul qilishda ALT qiymati :"
                    : "Значение АЛТ при поступлении :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="alt_value"
                  placeholder="12"
                  v-model="alt_value"
                  required
                />
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz"
                    ? "Qabul qilishda AST qiymati :"
                    : "Значение АСТ при поступлении :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="ast_value"
                  placeholder="17"
                  v-model="ast_value"
                  required
                />
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Umumiy xolesterin :" : "Общий холестерин :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="total_cholesterol"
                  placeholder="162"
                  v-model="total_cholesterol"
                  required
                />
              </div>
            </div>
            <div class="flex_block_item_mobile" style="width: 24%">
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Triglitseridlar :" : "Триглицериды :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="triglycerides"
                  placeholder="131"
                  v-model="triglycerides"
                  required
                />
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Kreatinin :" : "Креатинин :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="treatinine"
                  placeholder="118"
                  v-model="treatinine"
                  required
                />
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Glyukoza :" : "Глюкоза :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="glucose"
                  placeholder="5.3"
                  v-model="glucose"
                  required
                />
              </div>
              <div class="input_field">
                <span style="font-size: 17px">{{
                  lang == "uz" ? "Gemoglobin :" : "Гемоглобин :"
                }}</span>
                <input
                  class="inputField"
                  type="number"
                  name="hemoglobin"
                  placeholder="127"
                  v-model="hemoglobin"
                  required
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <div
        class="flex_block"
        style="justify-content: space-between; margin-top: 20px"
      >
        <div class="back_btn" v-if="show_tab != 1" @click="changeTabBack()">
          <div class="flex_block">
            <div style="margin-right: 10px">
              <a-icon type="left" theme="outlined" />
            </div>
            <div>{{ lang == "uz" ? "orqaga qaytish" : "назад" }}</div>
          </div>
        </div>
        <div class="next_btn" v-if="show_tab != 5" @click="changeTab()">
          <div class="flex_block">
            <div>{{ lang == "uz" ? "davom ettirish" : "продолжать" }}</div>
            <div style="margin-left: 10px">
              <a-icon type="right" theme="outlined" />
            </div>
          </div>
        </div>
        <div class="save_btn" v-if="show_tab == 5" @click="goToMain()">
          <div class="flex_block">
            <div>{{ lang == "uz" ? "saqlash" : "сохранять" }}</div>
            <div style="margin-left: 10px">
              <a-icon type="check-circle" theme="outlined" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router/index";
import axios from "@/axios.js";
export default {
  data() {
    return {
      show_tab: 1,
      lang: null,
      weight: 0,
      growth: 0,
      quetelet_index: 0,
      clinical_trial: 3,
      myocardial_infarction: 3,
      angina_pectoris: 3,
      heart_failure: 3,
      stroke: 3,
      peripheral_arterial: 3,
      lung_disease: 3,
      renal_failure: 3,
      percutaneous_coronary: 3,
      coronary_bypass: 3,
      coronary_stenoses: 3,
      acute_coronary: 4,
      smoking: 4,
      diabetes_mellitus: 6,
      arterial_hypertension: 3,
      family_hypertension: 3,
      hypercholesterolemia: 3,
      family_hypercholester: 3,
      family_coronary_artery: 3,
      family_analysis_hd: 3,
      family_vs: 3,
      family_diabetes: 3,
      aspirin: 3,
      antithrombotic_drugs: 5,
      anticoagulants: 5,
      beta_blockers: 3,
      ace_inhibitors: 3,
      angiotensin_2: 3,
      statins: 3,
      lipid_lowering: 5,
      nitrates: 3,
      channel_blockers: 3,
      patient_id: null,
      analysis_id: this.$route.query.analysis_id,
      main_symptom: 8,
      major_syndrome: 1,
      heart_rate: 0,
      syst_bp: 0,
      diast_bp: 0,
      ecg_rhythm: 5,
      qrs_complex: 5,
      st_t_changes: 6,
      ecg_changes: 4,
      troponin_t_level: 4,
      troponin_i_level: 4,
      mv_cpk: 4,
      alt_value: 0,
      ast_value: 0,
      total_cholesterol: 0,
      triglycerides: 0,
      treatinine: 0,
      glucose: 0,
      hemoglobin: 0,
      syndroms: "",
      qrs_datas: "",
    };
  },
  mounted() {
    if (localStorage.getItem("lang")) {
      this.lang = localStorage.getItem("lang");
    } else {
      localStorage.setItem("lang", "uz");
    }
    axios.get("/api/analysis/" + this.$route.query.analysis_id).then((res) => {
      this.patient_id = res.data.patientId;
      axios.get("/api/patients/" + this.patient_id).then((res) => {
        this.first_name = res.data.firstName;
        this.last_name = res.data.lastName;
        this.middle_name = res.data.middleName;
        this.birth_date = res.data.birthDate;
        this.address = res.data.address;
        this.phone_number = res.data.phoneNumber;
        this.gender = res.data.gender == 1 ? "ERKAK" : "AYOL";
        this.registrationId = res.data.registrationId;
      });
    });
  },
  methods: {
    setQuetletIndex() {
      this.quetelet_index = (
        this.weight /
        (this.growth * this.growth == 0 ? 1 : this.growth * this.growth)
      ).toFixed(4);
    },
    syndromArr(val) {
      if (this.syndroms.includes(val.toString())) {
        this.syndroms = this.syndroms.replace(val.toString(), "");
      } else {
        this.syndroms = this.syndroms + val.toString();
      }
      console.log(this.syndroms);
    },
    qrsComplexArr(val) {
      if (this.qrs_datas.includes(val.toString())) {
        this.qrs_datas = this.qrs_datas.replace(val.toString(), "");
      } else {
        this.qrs_datas = this.qrs_datas + val.toString();
      }
      console.log(this.qrs_datas);
    },
    changeTabs(val) {
      this.show_tab = val;
    },
    changeTab() {
      this.show_tab = this.show_tab + 1;
    },
    changeTabBack() {
      this.show_tab = this.show_tab - 1;
    },
    setGender(val) {
      this.gender = val;
    },
    goToMain() {
      if (this.weight && this.growth && this.ecg_rhythm) {
        axios
          .patch(`/api/analysis/${this.analysis_id}`, {
            weight: Number(this.weight),
            growth: Number(this.growth),
            quetelet_index: Number(this.quetelet_index),
            clinical_trial: Number(this.clinical_trial),
            myocardial_infarction: Number(this.myocardial_infarction),
            angina_pectoris: Number(this.angina_pectoris),
            heart_failure: Number(this.heart_failure),
            stroke: Number(this.stroke),
            peripheral_arterial: Number(this.peripheral_arterial),
            lung_disease: Number(this.lung_disease),
            renal_failure: Number(this.renal_failure),
            percutaneous_coronary: Number(this.percutaneous_coronary),
            coronary_bypass: Number(this.coronary_bypass),
            coronary_stenoses: Number(this.coronary_stenoses),
            acute_coronary: Number(this.acute_coronary),
            smoking: Number(this.smoking),
            diabetes_mellitus: Number(this.diabetes_mellitus),
            arterial_hypertension: Number(this.arterial_hypertension),
            family_hypertension: Number(this.family_hypertension),
            hypercholesterolemia: Number(this.hypercholesterolemia),
            family_hypercholester: Number(this.family_hypercholester),
            family_coronary_artery: Number(this.family_coronary_artery),
            family_analysis_hd: Number(this.family_analysis_hd),
            family_vs: Number(this.family_vs),
            family_diabetes: Number(this.family_diabetes),
            aspirin: Number(this.aspirin),
            antithrombotic_drugs: Number(this.antithrombotic_drugs),
            anticoagulants: Number(this.anticoagulants),
            beta_blockers: Number(this.beta_blockers),
            ace_inhibitors: Number(this.ace_inhibitors),
            angiotensin_2: Number(this.angiotensin_2),
            statins: Number(this.statins),
            lipid_lowering: Number(this.lipid_lowering),
            nitrates: Number(this.nitrates),
            channel_blockers: Number(this.channel_blockers),
            main_symptom: this.syndroms,
            major_syndrome: Number(this.major_syndrome),
            heart_rate: Number(this.heart_rate),
            syst_BP: Number(this.syst_bp),
            diast_BP: Number(this.diast_bp),
            ecg_rhythm: Number(this.ecg_rhythm),
            qrs_complex: this.qrs_datas,
            st_T_changes: Number(this.st_t_changes),
            ecg_changes: Number(this.ecg_changes),
            troponin_T_level: Number(this.troponin_t_level),
            troponin_I_level: Number(this.troponin_i_level),
            mv_CPK: Number(this.mv_cpk),
            alt_value: Number(this.alt_value),
            ast_value: Number(this.ast_value),
            total_cholesterol: Number(this.total_cholesterol),
            triglycerides: Number(this.triglycerides),
            treatinine: Number(this.treatinine),
            glucose: Number(this.glucose),
            hemoglobin: Number(this.hemoglobin),
            patientId: Number(this.patient_id),
            laboratoryId: Number(localStorage.getItem("role_id")) || 6, // shu qismi ishlamasligi mumkin user id bor yoqligiga qarab
          })
          .then(() => {
            // console.log(res);
            // let userLink = res.data._links.self.href;
            // let analysis_id = userLink.substring(
            //   userLink.lastIndexOf("/") + 1,
            //   userLink.length
            // );
            axios
              .post("/api/laboratories/submit", {
                patientId: Number(this.patient_id),
                analysisId: Number(this.analysis_id),
              })
              .then(() => {
                router.replace("/laboratory");
                this.$toast.success(
                  this.lang == "uz"
                    ? "Laboratoriya natijalari qo'shildi !"
                    : "Добавлены результаты лабораторных исследований !",
                  {
                    position: "top-right",
                    timeout: 5000,
                    draggablePercent: 0.6,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                  }
                );
              })
              .catch(() => {
                this.$toast.error(
                  this.lang == "uz"
                    ? "Ma'lumotlarni yuklashda xatolik yuz berdi !"
                    : "Произошла ошибка при загрузке данных !",
                  {
                    position: "top-right",
                    timeout: 5000,
                    draggablePercent: 0.6,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                  }
                );
              });
          })
          .catch(() => {
            this.$toast.error(
              this.lang == "uz"
                ? "Maydonlarni to'g'ri to'ldiring !"
                : "Заполняйте поля правильно !",
              {
                position: "top-right",
                timeout: 5000,
                draggablePercent: 0.6,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
              }
            );
          });
        // });
      } else {
        this.$toast.error(
          this.lang == "uz"
            ? "Vazni, bo'yi va EKG natijalarini kiriting !"
            : "Введите вес, рост и результаты ЭКГ!",
          {
            position: "top-right",
            timeout: 5000,
            draggablePercent: 0.6,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
          }
        );
      }
    },
  },
};
</script>

<style lang="scss">
// @import "https://fonts.googleapis.com/css?family=Montserrat:400,700|Raleway:300,400";

/* colors */
$blue: #428bff;
$red: #ff4a53;
$dark: #333;
$yellow: #1890ff;
$black: #000000;
$grey: #1890ff;
$accent: $blue;
$accent-inactive: desaturate($accent, 85%);
$secondary: $accent-inactive;

/* tab setting */
$tab-count: 4;
$indicator-width: 210px;
$indicator-height: 2px;

/* breakpoints */
$breakpoints: (
  medium: #{$tab-count * 250px},
  small: #{$tab-count * 150px},
);

/* selectors relative to radio inputs */
$label-selector: "~ ul > li";
$slider-selector: "~ .slider";
$content-selector: "~ .content > section";

// @mixin tabs(
//   $label-selector: $label-selector,
//   $slider-selector: $slider-selector,
//   $content-selector: $content-selector
// ) {
//   @for $i from 1 through $tab-count {
//     &:nth-of-type(#{$i}):checked {
//       #{$label-selector}:nth-child(#{$i}) {
//         @content;
//       }

//       #{$slider-selector} {
//         transform: translateX(#{100% * ($i - 1)});
//       }

//       #{$content-selector}:nth-child(#{$i}) {
//         display: block;
//       }
//     }
//   }
// }

.flex_class {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.flex_block {
  display: flex !important;
  align-items: center !important;
}

.flex_block_mobile {
  display: flex;
  justify-content: space-between;
}

.flex_block_item_mobile {
  width: 30%;
}

.input_field {
  position: relative;
  margin-bottom: 20px;
  -webkit-animation: bounce 0.6s ease-out;
  animation: bounce 0.6s ease-out;
}

.inputField {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0.375rem 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: black;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  outline: none;
  font-family: "Montserrat";
}

input {
  &[type="text"],
  &[type="date"],
  &[type="email"],
  &[type="number"] {
    width: 100%;
    padding: 8px 10px 9px 10px;
    height: 35px;
    border: 1px solid $grey;
    box-sizing: border-box;
    outline: none;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 16px;
  }
  &[type="text"]:hover,
  &[type="date"]:hover,
  &[type="number"]:hover {
    background: #fafafa;
  }
  &[type="text"]:focus,
  &[type="number"]:focus,
  &[type="date"]:focus {
    -webkit-box-shadow: 0 0 2px 1px #1890ff;
    -moz-box-shadow: 0 0 2px 1px #1890ff;
    box-shadow: 0 0 2px 1px #1890ff;
    border: 1px solid $yellow;
    background: #fafafa;
  }
  &[type="submit"] {
    background: $yellow;
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    color: #fff;
    font-size: 1.1em;
    margin-bottom: 10px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: darken($yellow, 7%);
    }
    &:focus {
      background: darken($yellow, 7%);
    }
  }
  &[type="checkbox"],
  &[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.select_option {
  position: relative;
  width: 100%;
  select {
    display: inline-block;
    width: 100%;
    height: 35px;
    padding: 0px 15px;
    cursor: pointer;
    color: black;
    border: 1px solid $grey;
    border-radius: 5px;
    font-size: 16px;
    background: #fff;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 0.2s ease;
    &::-ms-expand {
      display: none;
    }
    &:hover,
    &:focus {
      color: black;
      background: #fafafa;
      border-color: black;
      outline: none;
    }
  }
}
.select_arrow {
  position: absolute;
  top: calc(50% - 4px);
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-width: 8px 5px 0 5px;
  border-style: solid;
  border-color: #7b7b7b transparent transparent transparent;
}

.select_option select {
  &:hover + .select_arrow,
  &:focus + .select_arrow {
    border-top-color: black;
  }
}

.next_btn {
  font-style: normal;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #fff;
  background: #428bff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* max-width: 220px; */
  height: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  padding: 0 12px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}
.back_btn {
  font-style: normal;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #fff;
  background: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* max-width: 220px; */
  height: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  padding: 0 12px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.save_btn {
  font-style: normal;
  border-radius: 8px;
  border: none;
  outline: none;
  color: #fff;
  background: #52c41a;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  /* max-width: 220px; */
  height: 35px;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  padding: 0 12px;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

.svg_icon {
  fill: #428bff !important;
}

html {
  width: 100%;
  height: 100%;
}

.body_page {
  background: #efefef;
  color: $dark;
  font-family: "Raleway";
  height: 100%;
  padding-top: 50px;
  h1 {
    text-align: center;
    color: $accent;
    font-weight: 300;
    padding: 40px 0 20px 0;
    margin: 0;
  }
}

.tabs {
  left: 50%;
  transform: translateX(-50%);
  position: relative;
  background: white;
  padding: 50px;
  padding-bottom: 35px;
  margin-bottom: 30px;
  width: 90%;
  min-height: 250px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  border-radius: 5px;
  min-width: #{$tab-count * 60px};
  input[name="tab-control"] {
    display: none;
  }

  .content section h2,
  ul li label {
    font-family: "Montserrat";
    font-weight: bold;
    font-size: 18px;
    color: $accent;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    li {
      box-sizing: border-box;
      flex: 1;
      width: #{100% / $tab-count};
      padding: 0 10px;
      text-align: center;

      label {
        transition: all 0.3s ease-in-out;
        color: $secondary;
        padding: 5px auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
        -webkit-touch-callout: none;
        // @include user-select(none);

        br {
          display: none;
        }

        svg {
          fill: $secondary;
          height: 1.2em;
          vertical-align: bottom;
          margin-right: 0.2em;
          transition: all 0.2s ease-in-out;
        }

        &:hover,
        &:focus,
        &:active {
          outline: 0;
          color: lighten($secondary, 15%);
          svg {
            fill: lighten($secondary, 15%);
          }
        }
      }
    }
  }

  .slider {
    position: relative;
    width: #{100% / $tab-count};
    transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
    padding-bottom: 20px;
    .indicator {
      position: relative;
      width: $indicator-width;
      max-width: 100%;
      margin: 0 auto;
      height: $indicator-height;
      background: $accent;
      border-radius: 1px;
    }
  }

  .content {
    padding-top: 30px;
    min-height: 300px;
    // border-top: 1px solid #428bff;

    section {
      // display: none;
      animation: {
        name: content;
        direction: normal;
        duration: 0.3s;
        timing-function: ease-in-out;
        iteration-count: 1;
      }
      line-height: 1.4;

      h2 {
        color: $accent;
        display: none;
        &::after {
          content: "";
          position: relative;
          display: block;
          width: 30px;
          height: 3px;
          background: $accent;
          margin-top: 5px;
          left: 1px;
        }
      }
    }
  }

  // input[name="tab-control"] {
  //   @include tabs {
  //     > label {
  //       cursor: default;
  //       color: $accent;

  //       svg {
  //         fill: $accent;
  //       }

  //       @media (max-width: map-get($breakpoints, small)) {
  //         background: rgba(0, 0, 0, 0.08);
  //       }
  //     }
  //   }
  // }

  @keyframes content {
    from {
      opacity: 0;
      transform: translateY(5%);
    }
    to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @media (max-width: map-get($breakpoints, medium)) {
    ul li label {
      white-space: initial;

      br {
        display: initial;
      }

      svg {
        height: 1.5em;
      }
    }
  }

  @media (max-width: map-get($breakpoints, small)) {
    ul li label {
      padding: 5px;
      border-radius: 5px;

      span {
        display: none;
      }
    }

    .slider {
      display: none;
    }

    .content {
      margin-top: 20px;
      section h2 {
        display: block;
      }
    }

    .flex_block_mobile {
      display: block;
    }
    .flex_block_item_mobile {
      width: 100% !important;
    }
  }
}
details {
  margin-bottom: 20px;
}
summary {
  border: 1px solid #1890ff;
  border-collapse: collapse;
  border-radius: 4px;
  padding: 4px 15px;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: white;
  cursor: pointer;
}
details[open] > summary::marker {
  color: #0255f0;
}
select:focus,
summary:focus,
summary:active {
  box-shadow: 0 0 5px 1px #0255f0;
}

.multi_ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  margin-top: 2px;
  display: block !important;
  border: 1px solid #0255f0;
  box-shadow: 0 0 5px 1px #cccccc;
  border-radius: 5px;
}
.multi_li {
  margin: 0px;
  padding: 0px;
  width: 100% !important;
  margin-top: 1px;
}
.multi_li > label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.multi_li > label:hover,
.multi_li > label:has(input:checked) {
  background-color: #1890ff;
  color: white !important;
}
</style>